import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LOCALE } from '@link/portal/services/const';
import enPortalText from './lang/en';
import jaPortalText from './lang/ja';
import koPortalText from './lang/ko';
import zhPortalText from './lang/zh';
import ztPortalText from './lang/zt';

export const LOCALE_LIST = ['en', 'ja', 'ko', 'zh', 'zt'];

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const resources = {
  en: {
    translation: {
      ...enPortalText
    }
  },
  ja: {
    translation: {
      ...jaPortalText
    }
  },
  ko: {
    translation: {
      ...koPortalText
    }
  },
  zh: {
    translation: {
      ...zhPortalText
    }
  },
  zt: {
    translation: {
      ...ztPortalText
    }
  }
} as const;

void i18n.use(initReactI18next).init({
  lng: DEFAULT_LOCALE,
  returnNull: false,
  resources
});

export function setLocale(locale: string): string {
  void i18n.changeLanguage(locale);
  document.documentElement.setAttribute('lang', locale.toLowerCase());
  localStorage.setItem('locale', locale);
  return locale;
}

function getLocaleParams() {
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang')?.toLowerCase() || '';
  if (LOCALE_LIST.includes(lang)) {
    return lang;
  }
}

(() => {
  const defaultLocale =
    getLocaleParams() || localStorage.getItem('locale') || DEFAULT_LOCALE;
  setLocale(defaultLocale);
})();

export default i18n;
