import React from 'react';
import { observer } from 'mobx-react-lite';
import { Warning } from '@linktivity/link-icons';
import { useStore } from '@link/portal/stores';
import styles from './message.module.css';

const WarningMessages: React.FC = () => {
  const { bookingData } = useStore();
  const { warningMessages } = bookingData;

  if (!warningMessages.length) {
    return null;
  }

  return (
    <div className={styles.message}>
      <Warning className={styles.messageWarningIcon} />
      <div className={styles.messageContent}>
        {warningMessages.map(m => {
          return (
            <div key={m} className={styles.messageItem}>
              <p className={styles.messageText}>{m}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ObservedWarningMessages = observer(WarningMessages);
export default ObservedWarningMessages;
