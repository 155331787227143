export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const SUPPLIER_TOKYO_CITY_PASS = 'tokyo_city_pass';

export const SUPPLIER_SURUTTOKANSAI = 'suruttokansai';

export const isSupportWebAssembly =
  typeof WebAssembly === 'object' &&
  typeof WebAssembly.instantiate === 'function';

/**
 * @description Check if the current browser is invalid
 */
export const isInvalidBrowser: boolean = (function () {
  const INVALID_BROWSERS_REGEX = /WpsMoffice|NAVER|SamsungBrowser/i;
  const userAgent = navigator.userAgent;
  return INVALID_BROWSERS_REGEX.test(userAgent);
})();

export const supplier = (() => {
  const supplierRegex = /issueticket\/([^\\/]+)\//;
  const result = location.pathname.match(supplierRegex);
  if (Array.isArray(result) && result.length >= 2) {
    return result[1];
  }
})();

export const isSuruttokansai = supplier === SUPPLIER_SURUTTOKANSAI;

export const isTokyoCityPass = supplier === SUPPLIER_TOKYO_CITY_PASS;
/**
 * @description check if is invalid supplier
 * @param invalidSuppliers Array of supplier
 */
export const isInvalidSupplier = (invalidSuppliers: string[]) => {
  const supplierRegex = /issueticket\/([^\\/]+)\//;
  const result = location.pathname.match(supplierRegex);
  if (Array.isArray(result) && result.length >= 2) {
    return invalidSuppliers.includes(result[1]);
  } else {
    return false;
  }
};

const compareVersions = (v1: number[], v2: number[]): number => {
  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = v1[i] || 0;
    const num2 = v2[i] || 0;
    if (num1 > num2) return 1;
    if (num1 < num2) return -1;
  }
  return 0;
};

/**
 * @description Check if the current iOS version is invalid
 * @param INVALID_MIN_IOS_VERSION The minimum invalid iOS version, e.g. '14.0.0'
 * @param INVALID_MAX_IOS_VERSION The maximum invalid iOS version, e.g. '14.0.2'
 */
export const isInvalidIosVersions = (
  INVALID_MIN_IOS_VERSION: string,
  INVALID_MAX_IOS_VERSION: string
) => {
  const minVersionParts = INVALID_MIN_IOS_VERSION.split('.').map(Number);
  const maxVersionParts = INVALID_MAX_IOS_VERSION.split('.').map(Number);
  const userAgent = navigator.userAgent;
  const regex = /iPhone OS (\d+)_(\d+)_?(\d+)?/;
  const inputVersionParts = userAgent.match(regex)?.slice(1).map(Number) || [
    0, 0, 0
  ];
  const isGreaterThanOrEqualMin =
    compareVersions(inputVersionParts, minVersionParts) >= 0;
  const isLessThanOrEqualMax =
    compareVersions(inputVersionParts, maxVersionParts) <= 0;

  return isGreaterThanOrEqualMin && isLessThanOrEqualMax;
};
