import { action, observable } from 'mobx';
import { eticketPortal } from '@link/portal/services';
import { NowRequest, NowResponse } from '@link/portal/services/types';
export class DateNow {
  @observable accessor nowData: NowResponse = {};
  @observable accessor offset = 0;

  @action
  getNowData = async (body: NowRequest) => {
    return await eticketPortal.now({ body });
  };

  @action
  setNowData(nowData: NowResponse) {
    this.nowData = nowData;
    this.updateNow(nowData?.now || Date.now());
  }

  @action
  updateNow = (date: Date | number | string) => {
    this.offset = new Date(date).getTime() - Date.now();
  };

  currentTime = () => {
    return Date.now() + this.offset;
  };
}

export default new DateNow();
