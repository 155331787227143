import { action, observable, toJS, computed } from 'mobx';
import { eticketPortal } from '@link/portal/services';
import {
  GetTicketResponse,
  GetTicketRequest,
  OperationHistory
} from '@link/portal/services/types';

export class TicketData {
  @observable accessor loading = false;
  @observable accessor ticketParams: GetTicketRequest = {};
  @observable accessor ticketData: GetTicketResponse = {};
  @observable accessor operationHistories: OperationHistory[] = [];
  @observable accessor unitIndex: number = 0;

  @action
  async getTicketData(body: GetTicketRequest) {
    try {
      this.setLoading(true);
      const ticketData = await eticketPortal.getTicket({ body });
      if (ticketData) {
        this.setTicketData(ticketData);
      }
      return ticketData;
    } finally {
      this.setLoading(false);
    }
  }

  @action
  reloadTicket = async () => {
    // keep this pointer
    await this.getTicketData(this.ticketParams);
  };

  @action
  async getOperationHistories(body: GetTicketRequest) {
    const { operationHistories } = await eticketPortal.getTicketHistories({
      body
    });
    if (operationHistories?.length) {
      this.setOperationHistories(operationHistories);
      return operationHistories;
    }
    return [];
  }

  @action
  async startUsing(body: GetTicketRequest) {
    await eticketPortal.startUsingTicket({ body });
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setTicketParams(params: GetTicketRequest) {
    this.ticketParams = params;
  }

  @action
  setTicketData(detail: GetTicketResponse) {
    this.ticketData = detail;
  }

  @action
  clearTicketData() {
    this.ticketData = {} as GetTicketResponse;
    this.operationHistories = [];
  }

  @action
  setOperationHistories(histories: OperationHistory[]) {
    this.operationHistories = histories;
  }

  @action
  setUnitIndex(index: number) {
    this.unitIndex = index;
  }

  @computed
  get ticket() {
    return toJS(this.ticketData.ticket) || {};
  }

  @computed
  get globalUiSetting() {
    return toJS(this.ticket.globalUiSetting) || {};
  }

  @computed
  get completed() {
    return !!this.ticketData.ticket?.id;
  }
}

export default new TicketData();
