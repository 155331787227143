import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { observer } from 'mobx-react-lite';
import { isInvalidBrowser, isInvalidSupplier, ud } from '@link/portal/utils';
import { useStore } from '@link/portal/stores';
import { RecommendBrowser } from '@link/portal/components';
import OTP from '../OTP';

const GTM_ENVIRONMENTS = ['develop', 'production'];
const INTERVAL_DURATION = 30_000;

const ETicketContainer: React.FC = () => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const { supplierId, secret, bookingId, distributionId } = useParams();
  const { dateNow } = useStore();

  const showRecommendBrowser = useMemo(() => {
    const invalidSuppliers = ['suruttokansai'];
    return isInvalidSupplier(invalidSuppliers) && isInvalidBrowser;
  }, []);

  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  const fetchNow = useCallback(() => {
    dateNow
      .getNowData({ bookingId, supplierId, secret, distributionId, ud })
      .then(nowData => {
        if (nowData?.now) {
          dateNow.updateNow(nowData.now);
        } else {
          stopInterval();
        }
      });
  }, [dateNow, bookingId, distributionId, secret, supplierId, stopInterval]);

  useEffect(() => {
    if (!showRecommendBrowser) {
      fetchNow();
      intervalRef.current = setInterval(fetchNow, INTERVAL_DURATION);
      return () => {
        stopInterval();
      };
    }
  }, [showRecommendBrowser, fetchNow, stopInterval]);

  useEffect(() => {
    if (GTM_ENVIRONMENTS.includes(import.meta.env.VITE_APP_PORTAL_ENV || '')) {
      const tagManagerArgs = {
        gtmId: 'GTM-WF7VLBNJ'
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <>
      {showRecommendBrowser ? (
        <RecommendBrowser />
      ) : (
        <>
          <OTP />
          <Outlet />
        </>
      )}
    </>
  );
};

const ObservedETicketContainer = observer(ETicketContainer);
export default ObservedETicketContainer;
