import { EticketPortalMixer } from '@linktivity/eticketportal';

const Language = EticketPortalMixer.Language;
type Language = EticketPortalMixer.Language;

const Country = EticketPortalMixer.Country;
type Country = EticketPortalMixer.Country;

const SubStatusId = EticketPortalMixer.SubStatusId;
type SubStatusId = EticketPortalMixer.SubStatusId;

const TicketTypeId = EticketPortalMixer.TicketTypeId;
type TicketTypeId = EticketPortalMixer.TicketTypeId;

const CodeType = EticketPortalMixer.CodeType;
type CodeType = EticketPortalMixer.CodeType;

const SupplierOperation = EticketPortalMixer.SupplierOperation;
type SupplierOperation = EticketPortalMixer.SupplierOperation;

const StatusId = EticketPortalMixer.EticketportalmixerStatusId;
type StatusId = EticketPortalMixer.EticketportalmixerStatusId;

const CouponStatusId = EticketPortalMixer.SelfRedemptionOptionPresetStatusId;
type CouponStatusId = EticketPortalMixer.SelfRedemptionOptionPresetStatusId;

const CheckInCodeId = EticketPortalMixer.CheckInCodeId;
const CheckInCodeValidationId = EticketPortalMixer.CheckInCodeValidationId;

const LANGUAGE_MAP = {
  en: Language.English,
  ja: Language.Japanese,
  ko: Language.Korean,
  zh: Language.Chinese,
  zt: Language.ChineseT
};

const REVERSE_LANGUAGE_MAP = Object.entries(LANGUAGE_MAP).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as Record<Language, string>
);

const DEFAULT_LANGUAGE = Language.Japanese;

const DEFAULT_LOCALE = REVERSE_LANGUAGE_MAP[DEFAULT_LANGUAGE];

const ETICKET_TITLE = 'eTicket portal';

const VALID_TICKET_STATUSES = [
  StatusId.Usable,
  StatusId.Unused,
  StatusId.InUse
] as const;

type VALID_TICKET_STATUSES = (typeof VALID_TICKET_STATUSES)[number];

const SHOW_TICKET_STATUSES = [
  StatusId.Invalid,
  StatusId.Awaiting,
  StatusId.Used,
  StatusId.Expired,
  StatusId.Distributed,
  StatusId.Returned
] as const;

type SHOW_TICKET_STATUSES = (typeof SHOW_TICKET_STATUSES)[number];

export {
  Language,
  Country,
  SubStatusId,
  TicketTypeId,
  CodeType,
  SupplierOperation,
  StatusId,
  CouponStatusId,
  CheckInCodeId,
  CheckInCodeValidationId,
  LANGUAGE_MAP,
  REVERSE_LANGUAGE_MAP,
  ETICKET_TITLE,
  DEFAULT_LANGUAGE,
  DEFAULT_LOCALE,
  VALID_TICKET_STATUSES,
  SHOW_TICKET_STATUSES
};
