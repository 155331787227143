import { useCallback, useMemo, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Loading } from '@linktivity/link-ui';
import { getCurrentLocale, ud, updateThemeColor } from '@link/portal/utils';
import { useStore } from '@link/portal/stores';
import styles from './ticket.module.css';

const TicketContainer: React.FC = () => {
  const { supplierId, secret, bookingId, ticketId, distributionId } =
    useParams();
  const { ticketData } = useStore();
  const { ticket } = ticketData;
  const loading = useMemo(() => !ticket.id, [ticket]);

  const ticketParams = useMemo(
    () => ({
      bookingId,
      supplierId,
      secret,
      ticketId,
      distributionId,
      ud,
      locale: getCurrentLocale()
    }),
    [bookingId, supplierId, secret, ticketId, distributionId]
  );

  const getTicket = useCallback(async () => {
    await ticketData.getTicketData(ticketParams);
  }, [ticketData, ticketParams]);

  useEffect(() => {
    if (ticket?.globalUiSetting?.colorPrimaryHex) {
      updateThemeColor(ticket?.globalUiSetting?.colorPrimaryHex);
    }
  }, [ticket?.globalUiSetting?.colorPrimaryHex]);

  useEffect(() => {
    ticketData.setTicketParams(ticketParams);
  }, [ticketData, ticketParams]);

  useEffect(() => {
    getTicket();
    return () => {
      ticketData.clearTicketData();
    };
  }, [getTicket, ticketData]);

  return (
    <>
      {loading ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

const ObservedTicketContainer = observer(TicketContainer);
export default ObservedTicketContainer;
