/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError
} from 'react-router-dom';
import {
  BaseLayout,
  ETicketContainer,
  BookingContainer,
  TicketContainer,
  VoucherContainer
} from '@link/portal/layouts';

const Booking = lazy(() => import('@link/portal/views/Booking'));
const BookingDetails = lazy(() => import('@link/portal/views/BookingDetails'));
const TicketDetails = lazy(() => import('@link/portal/views/TicketDetails'));
const TicketHistory = lazy(() => import('@link/portal/views/TicketHistory'));
const Dashboard = lazy(() => import('@link/portal/views/Dashboard'));
const NotFound = lazy(() => import('@link/portal/views/NotFound'));
const VoucherDetails = lazy(() => import('@link/portal/views/VoucherDetails'));
const VoucherList = lazy(() => import('@link/portal/views/VoucherList'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<NotFound />} />
          <Route element={<ETicketContainer />}>
            <Route
              path="issueticket/:supplierId/:secret/:bookingId"
              element={<BookingContainer />}
            >
              <Route index element={lazyView(<Booking />)} />
              <Route path="details" element={lazyView(<BookingDetails />)} />
            </Route>
            <Route
              path="issueticket/:supplierId/:secret/:bookingId/:ticketId/:distributionId?"
              element={<TicketContainer />}
            >
              <Route index element={lazyView(<TicketDetails />)} />
              <Route path="history" element={lazyView(<TicketHistory />)} />
            </Route>
          </Route>
          <Route path="dashboard" element={lazyView(<Dashboard />)} />
          <Route element={<VoucherContainer />}>
            <Route
              path="voucher/:bookingId/:secret"
              element={lazyView(<VoucherList />)}
            />
            <Route
              path="voucher/:bookingId/:secret/:fileName"
              element={lazyView(<VoucherList />)}
            />
            <Route
              path="voucher/detail/:bookingId/:secret"
              element={lazyView(<VoucherDetails />)}
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
