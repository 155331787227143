export const ViewFinder = () => (
  <>
    <svg
      viewBox="0 0 100 100"
      style={{
        top: 0,
        left: 0,
        zIndex: 1,
        color: 'var(--color-primary)',
        boxSizing: 'border-box',
        border: 'min(12vw, 80px) solid rgba(0, 0, 0, 0.3)',
        position: 'absolute',
        width: '100%',
        height: '100%'
      }}
    >
      <path
        fill="none"
        d="M23 5H8C6.34315 5 5 6.34315 5 8V23M95 23V8C95 6.34315 93.6569 5 92 5H77M77 95H92C93.6569 95 95 93.6569 95 92V77M5 77V92C5 93.6569 6.34315 95 8 95H23"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
