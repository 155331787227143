import { action, makeObservable, observable } from 'mobx';

export class OTP {
  @observable accessor registerOTP = false;
  @observable accessor requestOTP = false;
  @observable accessor verifyOTP = false;

  constructor() {
    makeObservable(this);
  }

  @action
  updateRegisterOTP = (visible: boolean) => {
    this.registerOTP = visible;
  };

  @action
  updateRequestOTP = (request: boolean) => {
    this.requestOTP = request;
  };

  @action
  updateVerifyOTP = (visible: boolean) => {
    this.verifyOTP = visible;
  };
}

export default new OTP();
