import { isProd } from '@link/portal/utils/context';
import * as Sentry from '@sentry/react';

const SENTRY_ENVIRONMENTS = ['develop', 'production'];

if (SENTRY_ENVIRONMENTS.includes(import.meta.env.VITE_APP_PORTAL_ENV || '')) {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_PORTAL_ENV,
    release: import.meta.env.VITE_APP_ETICKET_TAG,
    // tunnel: '/sentry',
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProd ? 0.01 : 1.0,
    ignoreErrors: [
      'AbortError: Share canceled',
      'AbortError: The operation was aborted.',
      'Abort due to cancellation of share'
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i
    ]
  });
}
