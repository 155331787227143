export default {
  common: {
    colon: ': ',
    cancel: 'Cancel',
    confirm: 'Confirm',
    backToTickets: 'Back to all tickets',
    backToTicketDetails: 'Back to ticket details',
    day: 'd',
    hour: 'h',
    min: 'm',
    second: 's'
  },
  views: {
    common: {
      submit: 'Submit',
      resend: 'Resend',
      cancel: 'Cancel',
      confirm: 'Confirm',
      tickets: 'All tickets',
      copyBtn: 'Copy the URL',
      recommendBrowserTips:
        'Your browser is currently not supported, please use a recommended browser.'
    },
    voucher: {
      bookingId: 'Booking ID',
      bookingDetails: 'Booking details',
      printable: 'Printable Voucher',
      participation: 'Instruction for Participation',
      inclusion: 'Inclusion',
      restrictions: 'Restrictions',
      requiredAttire: 'Required items to bring & attire',
      requirements: 'Requirements',
      other: 'Other info',
      localContact: 'Local contact',
      cancellation: 'Cancellation policy',
      clickUrl: 'Please click on this link to redeem your ticket'
    },
    booking: {
      details: 'Reservation details',
      bookingId: 'Booking {{id}}',
      history: 'Usage history',
      bookedDate: 'Reservation date',
      participationLocations: 'Participation location',
      participationLocationKind: {
        MAP_OF_MEETING_POINT: 'Meeting point',
        NOTE_OF_LOCATION_AND_DIRECTION: 'Note of location and direction',
        LANDMARKS_TO_MEETING_POINT: 'Nearby landmarks',
        PICK_UP_CAR: 'Pick-up vehicles',
        PICK_UP_GUIDE: 'Transportation personnel',
        ITINERARY: 'Itinerary',
        PARKING: 'Parking information'
      },
      ticketType: {
        CODE_CPM: 'Show QR code',
        CODE_MPM: 'Scan QR code',
        BARCODE_CPM: 'Show barcode',
        SELF_REDEMPTION: 'Show ticket',
        SIMPLE_VOUCHER: 'Show ticket'
      },
      inclusion: 'Inclusion',
      restrictions: 'Restrictions',
      requirements: 'Requirements',
      attireItems: 'Required items to bring & attire',
      otherInfo: 'Remarks / Others',
      cancellationPolicy: 'Cancellation policy'
    },
    otp: {
      title: 'One-time password',
      tips1:
        'Your access environment has changed. \n For security protection, a one-time password will be sent.',
      tips2:
        'Please register your email address to receive the one-time password.',
      inputOTPCode: 'Please enter the one-time password provided in the email.',
      sendCode:
        'A one-time password has been sent to {{email}}. Please check your email.',
      invalidEmail: 'Invalid Email address.',
      inputEmail: 'Please enter your Email address.',
      inputPassword: 'Please enter the one-time password.',
      sendOTP: 'Send one-time password.'
    },
    ticket: {
      status: {
        INVALID: 'Invalid',
        AWAITING: 'Before the period',
        UNUSED: 'Not yet used',
        USABLE: 'Available for use',
        IN_USE: 'In use',
        USED: 'Used',
        EXPIRED: 'Expired',
        DISTRIBUTED: 'Distributed',
        RETURNED: 'Returned'
      },
      subStatus: {
        ENTERED: 'Entered the station',
        EXITED: 'Departed from the station',
        INITIAL_STATION_CANCELLED: 'Departure station cancellation'
      },
      nowTime: 'Current time',
      startTime: 'Start time',
      QRCodeExpirationRemainingTime: 'QR code validity period remaining',
      QRCodeAwaiting:
        "After the ticket's valid period starts, the QR code will be displayed",
      screenShotTips:
        'Please note that you cannot gain entry using a screenshot of the QR code.',
      showScreenToStaff: 'Please present this screen to the staff.',
      instruction: 'Usage instructions',
      usedAt: 'Date of use',
      availablePeriod: 'Available Period',
      bookingId: 'Booking ID',
      startUsing: 'Start using',
      startUsingTips: 'Would you like to use your ticket now?',
      startUsingWarn:
        '*Once you start using, the validity period will start immediately.',
      selfRedemptionOptionPresetPlaceholder:
        'Please select the self-redeem option',
      distributeTicket: 'Distribute',
      distributedTicket: 'Distributed',
      distributeTicketTips:
        'If you cannot copy the link from the share button, please copy the above link to share the tickets with your companions.',
      reDistributeTicket: 'Redistribute tickets',
      reDistributeTips:
        '*Ticket redistribution is only permitted if a person has an unused ticket.',
      reDistributeTips2:
        '*The distributed link will become invalid after redistribution.',
      copyLinkSuccess: 'The link has already been copied.',
      use: 'Use',
      confirmUse: 'Confirm usage details',
      useTicket: 'Use <span>{{count}}</span> tickets',
      issueTicketTips: 'Please use it in front of the staff',
      issueTicketNotes: '*Cancellation is not possible after use',
      undo: 'Undo',
      redeem: 'Redeem ticket',
      thanks: 'Thanks',
      second: '{{count}} SEC',
      expirationDate: 'Expiration date',
      supplier: 'Supplier',
      participatorName: 'Participant name',
      validPeriod: 'Valid period after used',
      fixedTime: '{{day}} days (until {{time}} on the last day, local time)',
      enableCamera: 'Please enable the camera',
      qrTip: 'Place the QR code in the box to scan automatically',
      accessDeniedTip: 'Camera access denied',
      manualVerify: 'Manual verification',
      unit: '{{unit}} {{index}}',
      afterUse: 'Used',
      participationDate: 'Activity date',
      participationTime: 'Participation time'
    },
    confirmUse: {
      title: 'Start using it?',
      usedTitle: 'Already used!',
      tips: 'Please slide in front of the staff. If used in advance, entry will not be allowed.',
      usedTips: 'The ticket cannot be reused. Have a great trip!',
      lockedText: 'Slide in front of staff',
      unlockText: 'Used',
      returnText: 'Back',
      closeText: 'Close'
    },
    history: {
      title: 'Usage history'
    },
    apiErrors: {
      baseError: 'There is an error occurred.',
      clientError: 'A error occurred while sending API request.',
      serverError: 'API server failed to fulfil the request.',
      connectionError:
        'An error occurred while connecting to API server. Please check your network and try again later.',
      requestError: 'An error occurred while setting up an API request.'
    }
  }
};
