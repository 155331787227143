import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, message } from '@linktivity/link-ui';
import { copyToClipboard } from '@linktivity/link-utils';
import { Safari, Chrome } from '@link/portal/assets/icons';
import { isInvalidBrowser } from '@link/portal/utils';
import styles from './recommend.module.css';

const BROWSERS = [
  {
    icon: Safari,
    name: 'Safari'
  },
  {
    icon: Chrome,
    name: 'Chrome'
  }
];

const RecommendBrowser: React.FC = () => {
  const { bookingId } = useParams();
  const { t } = useTranslation();

  const showRecommendBrowser = useMemo(() => {
    return isInvalidBrowser;
  }, []);

  const handleCopy = useCallback(() => {
    copyToClipboard(location.href);
    message.success(t('views.ticket.copyLinkSuccess'));
  }, [t]);

  const renderBrowserIcons = () => (
    <div className={styles.recommendIcons}>
      {BROWSERS.map(({ icon: Icon, name }) => (
        <div className={styles.recommendIcon} key={name}>
          <div className={styles.recommendIconContainer}>
            <Icon className={styles.icon} />
            <span>{name}</span>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    showRecommendBrowser && (
      <div className={styles.recommend}>
        {bookingId && (
          <h1 className={styles.recommendTitle}>Booking {bookingId}</h1>
        )}
        <p className={styles.recommendContent}>
          {t('views.common.recommendBrowserTips')}
        </p>
        {renderBrowserIcons()}
        <div className={styles.recommendUrl}>
          <div className={styles.recommendUrlContainer}>{location.href}</div>
        </div>
        <Button className={styles.recommendBtn} onClick={handleCopy}>
          {t('views.common.copyBtn')}
        </Button>
      </div>
    )
  );
};

export default RecommendBrowser;
