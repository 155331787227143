import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loading } from '@linktivity/link-ui';
import { useStore } from '@link/portal/stores';
import { getCurrentLocale, updateThemeColor, ud } from '@link/portal/utils';
import { useTitle } from '@link/portal/hooks';
import { ETICKET_TITLE } from '@link/portal/services';
import styles from './booking.module.css';

const BookingContainer: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { bookingId, supplierId, secret } = useParams();
  const { i18n } = useTranslation();
  const { bookingData } = useStore();

  useTitle(bookingData.booking.activityTitle || ETICKET_TITLE);

  useEffect(() => {
    if (bookingData?.booking.uiSetting?.colorPrimaryHex) {
      updateThemeColor(bookingData?.booking?.uiSetting?.colorPrimaryHex);
    }
  }, [bookingData?.booking.uiSetting?.colorPrimaryHex]);

  const getBooking = useCallback(async () => {
    await bookingData.getBookingData({
      bookingId,
      supplierId,
      secret,
      ud,
      locale: getCurrentLocale(i18n.language)
    });
    setLoading(false);
  }, [bookingData, bookingId, supplierId, secret, i18n.language]);

  useEffect(() => {
    getBooking();
  }, [getBooking]);

  return (
    <>
      {loading ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

const ObservedBookingContainer = observer(BookingContainer);
export default ObservedBookingContainer;
