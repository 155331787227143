import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect
} from 'react';
import cls from 'clsx';
import {
  Loading,
  Popper,
  Input,
  ScrollLoader,
  LoaderReponse
} from '@linktivity/link-ui';
import { noop, eventKey, debounce } from '@linktivity/link-utils';
import { useListKeyboardNav } from '@linktivity/link-hooks';
import { Down, Clear, Search } from '@linktivity/link-icons';
import {
  ISelectProps,
  ISingleSelectProps,
  IBasicSingleSelectProps,
  SelectOptionType,
  SelectValueType
} from './Select.types';
import Option from './Option';
import styles from './select.module.css';

const isBasicProps = (
  props: ISingleSelectProps
): props is IBasicSingleSelectProps => 'options' in props;

const SingleSelect: React.FC<ISingleSelectProps> = props => {
  const {
    value = '',
    onSelect = noop,
    className = '',
    placeholder = '',
    disabled = false,
    clearable = false,
    filterable = false,
    invalid = false,
    placement = 'bottom',
    withArrow = false,
    withinPortal = true,
    sameWidth = true,
    onLazyLoad
  } = props;
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const clearRef = useRef<HTMLButtonElement>(null);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const onClose = useCallback(() => setVisible(false), []);
  const [handleKeyDown, setPopperRef] = useListKeyboardNav(visible, onClose);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const initialLoaderRef = useRef(
    onLazyLoad && filterable ? debounce(onLazyLoad, 300) : onLazyLoad
  );
  useEffect(() => {
    const initialLoader = initialLoaderRef.current;
    if (initialLoader && visible) {
      setLoading(true);
      initialLoader({ search })
        .then(res => {
          setCompleted(!res.nextCursor);
        })
        .finally(() => setLoading(false));
    }
  }, [search, visible]);
  const inViewLoad = useCallback(
    (cursor: string) => {
      return onLazyLoad?.({
        search,
        cursor
      }).finally(() => setLoading(false)) as Promise<LoaderReponse>;
    },
    [onLazyLoad, search]
  );
  const mergedOptions: Array<SelectOptionType> = useMemo(() => {
    if (isBasicProps(props)) {
      return props.options;
    } else {
      const { children } = props;
      return (
        React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            const { value, label, search, disabled } =
              child.props as SelectOptionType;
            return {
              label,
              value,
              search,
              disabled
            };
          }
        }) || []
      );
    }
  }, [props]);
  const selectedLabel = useMemo(
    () => mergedOptions.find(option => option.value === value)?.label,
    [value, mergedOptions]
  );
  const handleClick = useCallback(
    (optionValue: SelectValueType) => {
      onSelect(optionValue);
      setVisible(false);
    },
    [onSelect]
  );
  const showClear = useMemo(
    () => clearable && !!selectedLabel,
    [clearable, selectedLabel]
  );

  const showPlaceholder = useMemo(
    () => placeholder && !selectedLabel,
    [placeholder, selectedLabel]
  );

  const handleClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onSelect('');
      referenceElement?.focus();
    },
    [onSelect, referenceElement]
  );

  const handleSelectedClick = useCallback(() => {
    if (!disabled) {
      setVisible(visible => !visible);
    }
  }, [disabled]);

  const handleSelectedKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      handleKeyDown(event);
      if (event.key === eventKey.Enter || event.key === eventKey.Space) {
        handleSelectedClick();
      }
    },
    [handleKeyDown, handleSelectedClick]
  );

  const selectOptions = useMemo(() => {
    if (filterable && search) {
      return mergedOptions.filter(option => {
        const label = option.search || option.label?.toString();
        return label?.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      return mergedOptions;
    }
  }, [mergedOptions, filterable, search]);
  const listView = useMemo(() => {
    if (isBasicProps(props)) {
      const itemData = selectOptions.map(option => (
        <Option
          key={option.value.toString()}
          value={option.value}
          selected={option.value === value}
          disabled={option.disabled}
          onClick={handleClick}
        >
          <button
            type="button"
            disabled={option.disabled}
            title={option.label?.toString()}
            className={cls(styles.menuItemButton, {
              [styles.selected]: option.value === value
            })}
          >
            <span className={styles.menuItemText}>{option.label}</span>
          </button>
        </Option>
      ));
      return itemData;
    } else {
      const { children } = props;
      return React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          const { value } = child.props;
          if (selectOptions.some(option => option.value === value)) {
            return React.cloneElement(child as React.ReactElement, {
              onClick: handleClick
            });
          }
        }
      });
    }
  }, [props, handleClick, value, selectOptions]);

  return (
    <>
      <div
        ref={setReferenceElement}
        tabIndex={disabled ? undefined : 0}
        role="combobox"
        aria-expanded={visible}
        aria-haspopup="listbox"
        onClick={handleSelectedClick}
        onKeyDown={handleSelectedKeyDown}
        className={cls(
          styles.ref,
          {
            [styles.disabled]: disabled,
            [styles.invalid]: invalid
          },
          className
        )}
      >
        <div className={styles.selection}>
          {showPlaceholder ? (
            <span className={cls(styles.selectionText, styles.placeholder)}>
              {placeholder}
            </span>
          ) : (
            <span className={styles.selectionText}>{selectedLabel}</span>
          )}
        </div>
        <div className={styles.icon}>
          <Down className={styles.down} />
          {showClear && (
            <button
              type="button"
              ref={clearRef}
              onClick={handleClear}
              onKeyDown={e => e.stopPropagation()}
              className={styles.clear}
            >
              <Clear className={styles.clearIcon} />
            </button>
          )}
        </div>
      </div>
      {!disabled && (
        <Popper
          referenceElement={referenceElement}
          placement={placement}
          withArrow={withArrow}
          withinPortal={withinPortal}
          sameWidth={sameWidth}
          visible={visible}
          onClose={onClose}
        >
          <div
            className={styles.select}
            ref={setPopperRef}
            onKeyDown={handleKeyDown}
          >
            {filterable && (
              <div className={styles.search}>
                <Input
                  suffix={<Search className={styles.searchIcon} />}
                  value={search}
                  clearable
                  onChange={e => setSearch(e.target.value)}
                />
              </div>
            )}
            {loading ? (
              <div className={styles.loading}>
                <Loading className={styles.loadingIcon} />
              </div>
            ) : (
              <div className={styles.menu} role="listbox">
                {listView}
                {onLazyLoad && !completed ? (
                  <ScrollLoader
                    className={styles.lazyLoader}
                    inView={inViewLoad}
                    updateCompleted={setCompleted}
                  />
                ) : null}
              </div>
            )}
          </div>
        </Popper>
      )}
    </>
  );
};

const Select: React.FC<ISelectProps> = props => {
  return <SingleSelect {...props} />;
};

export default Select;
